a {
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: underline;
}

.navbar-comp {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: 100vw;
    height: 2.5em;    
    padding-top: 2em;    
    padding-bottom: 1em;
}

.menu-item {
    margin-right: 1em;
}

.icon {
    width: 70%;
    height: auto;
    margin-top: 0.3em;
}

.avatar {
    width: 2.5em;
    height: auto;
    border-radius: 100%;
}

.search-zone {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#logo {
    padding-top: 12em;  
}

#text-search {
    display: flex;
    margin-top: 1em;
    width: 40em;
    height: 2.5em;
    border-radius: 5em / 5em;
    border: 1px solid #e0e0e0;
    flex-flow: row flex-start;
    justify-content: space-around;
    padding-top: 0.7em;
}

#text-search:hover {
    box-shadow: 0em 0.1em 0.4em 0.1em #e0e0e0;
}

.searchbox {
    height: 1.8em;
    width: 30em;
    border: none;
    font-size: 1em;
}

.searchbox:focus {
    outline: none;
}

.magnifying {
    width: 1em;
    margin-top: -0.5em;
}

.voice-icon {
    width: 1.5em;
    margin-top: -0.5em;
}

.search-panal {
    display: flex;
    justify-content: center;
    padding-top: 3em;
}

.search-panal-text {
    display: flex;
    justify-content: center;
    padding-top: 2em;
}

.search-btn {    
    font-size: 0.9em;
    padding: 1.5em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    border: none;
    border-radius: 0.2em;
    background-color: #F8F9FA;
    color: #3c4043;
    font-weight: 500;
    margin-right: 1em;
}

.search-btn:hover {
    outline: 0.5px solid #e0e0e0;
    box-shadow: 0em 0.15em 0.1em 0.05em #e0e0e0;
}

.subtext {
    font-size: 0.85em;
}

.lang-link {
    color: #1a0dab;
    padding-left: 0.5em;
}


.result-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    padding: 1em;    
    padding-top: 2em;
}

.logo-sm {
    width: 6em;
    height: auto;
}

.result-title {
    font-size: 1.5em;
    color: rgb(0, 0, 181);
}

.result-title:hover {
    text-decoration: underline;
    cursor: pointer;
}